import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, CardContent, Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { formatDistanceInMiles } from "@src/appV2/lib/utils/distance";
import { BottomSheet } from "@src/appV2/redesign/components/BottomSheet";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import { Illustration } from "@src/appV2/redesign/components/Illustration";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { format, formatDistanceToNow } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import { useHistory } from "react-router-dom";

import { Button } from "../../components/Button";
import { Card } from "../../components/Card";
import { CbhIcon } from "../../components/CbhIcon";
import { DotSeparatedList } from "../../components/DotSeparatedList";
import { Pill } from "../../components/Pill";
import { type PlacementDetail } from "../api/useGetPlacement";

interface InterviewBookedDialogProps {
  modalState: UseModalState;
  placementDetails: PlacementDetail;
  selectedInterviewTime: {
    start: Date;
    end: Date;
  };
}

export function InterviewBookedDialog(props: InterviewBookedDialogProps) {
  const { modalState, placementDetails, selectedInterviewTime } = props;
  const history = useHistory();
  const worker = useDefinedWorker();

  function getTimeOfDayIcon(date: Date): "sun-filled" | "sunset-filled" {
    const hour = date.getHours();
    // Consider daytime between 6 AM and 12 PM
    return hour >= 6 && hour < 12 ? "sun-filled" : "sunset-filled";
  }

  const interviewWorkplaceDetails = [
    isDefined(placementDetails.workerTypes) && (
      <Text key="shift-types" variant="body2">
        {placementDetails.workerTypes?.join(", ")}
      </Text>
    ),
    <Text key="workplace-name" variant="body2">
      {placementDetails.workplace.name}
    </Text>,
    <Text key="distance" variant="body2">
      {formatDistanceInMiles(placementDetails.distance)}
    </Text>,
  ].filter(Boolean);

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter
          orientation="horizontal"
          onClose={() => {
            modalState.closeModal();
          }}
        >
          <Button
            variant="contained"
            size="large"
            onClick={async () => {
              history.replace(DeprecatedGlobalAppV1Paths.OPEN_SHIFTS);
            }}
          >
            Browse more jobs
          </Button>
        </DialogFooter>
      }
    >
      <Box sx={{ py: 10, px: 4 }}>
        <Stack spacing={7} alignItems="center" justifyContent="center">
          <Illustration type="shift-booked" />
          <Stack spacing={7} sx={{ px: 10 }}>
            <Text variant="h2" textAlign="center">
              Your interview is booked!
            </Text>
            <Text variant="body1" textAlign="center">
              You&apos;ll receive $20 for travel expenses once your interview is completed.
            </Text>
          </Stack>
          <Card outlined variant="tertiary" elevation={2} sx={{ width: "100%" }}>
            <CardContent>
              <Stack spacing={6}>
                <Pill
                  size="medium"
                  sx={{ width: "fit-content" }}
                  label={
                    <DotSeparatedList>
                      {format(selectedInterviewTime.start, "EEE, MMM d")}
                      {`Starts ${formatDistanceToNow(
                        zonedTimeToUtc(selectedInterviewTime.start, worker.tmz),
                        {
                          addSuffix: true,
                        }
                      )}`}
                    </DotSeparatedList>
                  }
                />
                <Stack direction="row" spacing={4}>
                  <Stack alignItems="center" sx={{ pt: 1 }}>
                    <CbhIcon type={getTimeOfDayIcon(selectedInterviewTime.start)} />
                  </Stack>
                  <Stack spacing={2}>
                    <Text semibold variant="h4">
                      {`${format(selectedInterviewTime.start, "h:mm aaa")} - ${format(
                        selectedInterviewTime.end,
                        "h:mm aaa"
                      )}`}
                    </Text>
                    <DotSeparatedList>{interviewWorkplaceDetails}</DotSeparatedList>
                  </Stack>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Box>
    </BottomSheet>
  );
}
