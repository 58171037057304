import { Box, Stack } from "@mui/material";
import { format, isSameDay } from "date-fns";

import { SelectableBox } from "../../components/SelectableBox";

interface InterviewDateSelectorProps {
  weekDays: Date[];
  selectedDate: Date | undefined;
  shouldDisableDate: (date: Date) => boolean;
  onDateChange: (date: Date) => void;
}

export function InterviewDateSelector(props: InterviewDateSelectorProps) {
  const { weekDays, selectedDate, shouldDisableDate, onDateChange } = props;

  const enabledDates = weekDays.filter((date) => !shouldDisableDate(date));

  return (
    <Stack spacing={2}>
      {/* The actual clickable buttons for each day */}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: `repeat(${enabledDates.length}, 1fr)`,
          gap: 1,
        }}
      >
        {enabledDates.map((date) => {
          const isSelected = selectedDate && isSameDay(date, selectedDate);

          return (
            <SelectableBox
              key={date.toISOString()}
              variant="secondary"
              isSelected={isSelected}
              onToggleSelect={() => {
                onDateChange(date);
              }}
            >
              {format(date, "EEE, MMM d")}
            </SelectableBox>
          );
        })}
      </Box>
    </Stack>
  );
}
