import { LoadingButton, Text, type UseModalState } from "@clipboard-health/ui-react";
import { Box, Stack } from "@mui/material";
import { BottomSheet } from "@src/appV2/redesign/components/BottomSheet";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import { Illustration } from "@src/appV2/redesign/components/Illustration";
import {
  type CreatePlacementChatChannelRequest,
  useCreateChatChannel,
} from "@src/appV2/ShiftBlocks/api/useCreateChatChannel";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { useHistory } from "react-router-dom";

import { type PlacementDetail } from "../api/useGetPlacement";
import { getPlacementChatPath } from "../paths";

interface PlacementApplicationSuccessBottomSheetProps {
  modalState: UseModalState;
  placementDetails: PlacementDetail;
}

export function PlacementApplicationSuccessBottomSheet(
  props: PlacementApplicationSuccessBottomSheetProps
) {
  const { modalState, placementDetails } = props;
  const history = useHistory();
  const worker = useDefinedWorker();
  const { mutateAsync: createChatChannel, isLoading: isCreatingChatChannel } =
    useCreateChatChannel<CreatePlacementChatChannelRequest>();
  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter
          orientation="vertical"
          onClose={() => {
            modalState.closeModal();
          }}
        >
          <LoadingButton
            fullWidth
            variant="contained"
            size="large"
            color="primary"
            isLoading={isCreatingChatChannel}
            disabled={isCreatingChatChannel}
            onClick={async () => {
              await createChatChannel({
                facilityId: placementDetails.workplace.id,
                placementId: placementDetails.id,
                agentId: worker.userId,
              });

              history.push(
                getPlacementChatPath(
                  placementDetails.id,
                  placementDetails.workplace.id,
                  "application-success-details"
                )
              );
            }}
          >
            View more details
          </LoadingButton>
        </DialogFooter>
      }
    >
      <Box sx={{ py: 8 }}>
        <Stack spacing={8} alignItems="center" justifyContent="center">
          <Illustration type="request-sent" />
          <Stack spacing={2}>
            <Text variant="h3" textAlign="center" sx={{ fontSize: "2.5rem" }}>
              Application
            </Text>
            <Text variant="h3" textAlign="center" sx={{ fontSize: "2.5rem" }}>
              sent!
            </Text>
          </Stack>
        </Stack>
      </Box>
    </BottomSheet>
  );
}
