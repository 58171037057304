// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/max-dependencies */
/* eslint complexity: ["error", 25] */
import { InternalLink, LoadingButton, Text, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { isCbhApiAxiosError } from "@src/appV2/Errors/errorUtilts";
import { useToast } from "@src/appV2/lib";
import { PlacementDetailCreateCandidateProfileDialog } from "@src/appV2/redesign/PlacementCandidate/components/PlacementDetailCreateCandidateProfileDialog";
import {
  type PlacementCandidate,
  PlacementCandidateStatus,
} from "@src/appV2/redesign/PlacementCandidate/types";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { type QueryObserverResult } from "@tanstack/react-query";
import { zonedTimeToUtc } from "date-fns-tz";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import { Button } from "../../components/Button";
import { useCreatePlacementApplication } from "../api/useCreatePlacementApplication";
import {
  transformInterviewAvailabilityData,
  useGetInterviewAvailability,
} from "../api/useGetInterviewAvailability";
import { type PlacementDetail } from "../api/useGetPlacement";
import { type PlacementResponse } from "../types/fetchPlacements.schema";
import { useIsJobInterviewsEnabled } from "../useIsJobInterviewsEnabled";
import { getApplyButtonLabel } from "../utils";
import { InterviewBookedDialog } from "./InterviewBookedDialog";
import { type InterviewTime } from "./InterviewTimeSelector";
import { PlacementApplicationSuccessBottomSheet } from "./PlacementApplicationSuccessBottomSheet";
import { PlacementDetailBookInterviewDialog } from "./PlacementDetailBookInterviewDialog";

interface PlacementApplyButtonContainerProps {
  placementDetails: PlacementDetail;
  placementCandidate: PlacementCandidate;
  refetchPlacement: () => Promise<QueryObserverResult<PlacementResponse>>;
}

export function PlacementApplyButtonContainer(props: PlacementApplyButtonContainerProps) {
  const { tmz: workerTimezone } = useDefinedWorker();
  const { placementDetails, placementCandidate, refetchPlacement } = props;
  const isJobInterviewsEnabled = useIsJobInterviewsEnabled();
  const [selectedInterviewTime, setSelectedInterviewTime] = useState<InterviewTime | undefined>(
    undefined
  );
  const { data: interviewAvailabilityResponse, isInitialLoading: isLoadingInterviewAvailability } =
    useGetInterviewAvailability(
      { workplaceId: placementDetails.workplace.id },
      {
        enabled:
          isDefined(placementDetails.workplace.id) &&
          placementDetails.interviewSettings?.scheduleInterviews &&
          isJobInterviewsEnabled,
      }
    );
  const interviewAvailability = transformInterviewAvailabilityData(
    interviewAvailabilityResponse,
    workerTimezone
  );
  const { showErrorToast } = useToast();
  const history = useHistory();
  const worker = useDefinedWorker();
  const { mutateAsync: createPlacementApplication, isLoading: isCreatingPlacementApplication } =
    useCreatePlacementApplication();
  const placementApplicationSuccessModalState = useModalState();
  const createCandidateProfileModalState = useModalState();
  const bookInterviewModalState = useModalState();
  const addInterviewToCalendarModalState = useModalState();
  const hasApplied =
    isDefined(placementDetails) && (placementDetails.applications?.length ?? 0) > 0;
  const applyButtonProps =
    hasApplied || placementDetails.isLicenseEligible
      ? {
          label: getApplyButtonLabel(hasApplied, placementDetails.interview),
          onClick: async () => {
            try {
              await createPlacementApplication({
                placementCandidateId: placementCandidate.id,
                placementDetails,
              });
              await refetchPlacement();
              placementApplicationSuccessModalState.openModal();
            } catch (error) {
              if (
                isCbhApiAxiosError(error) &&
                error.response.data.errors.some(
                  (error) =>
                    error.detail ===
                    "The candidate is not licensed for any of this placement's worker types in the facility's state"
                )
              ) {
                showErrorToast(
                  <Text variant="body2" color="inherit">
                    Application failed since you aren&apos;t licensed to work in the facility&apos;s
                    state.{" "}
                    <InternalLink
                      color="inherit"
                      sx={{ textDecorationColor: "inherit" }}
                      to={DeprecatedGlobalAppV1Paths.LICENSE_MANAGER}
                    >
                      Tap here
                    </InternalLink>{" "}
                    to update your licenses.
                  </Text>
                );
                return;
              }

              showErrorToast("Failed to apply for job.");
            }
          },
        }
      : {
          label: "Upload valid license",
          warning: `Please make sure your licenses are up to date and valid in ${placementDetails.workplace.state}`,
          onClick: () => {
            history.push(DeprecatedGlobalAppV1Paths.LICENSE_MANAGER);
          },
        };
  const showBookInterviewButton =
    placementDetails.interviewSettings?.scheduleInterviews &&
    !hasApplied &&
    isJobInterviewsEnabled &&
    placementDetails.isLicenseEligible;
  return (
    <>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          paddingX: 4,
          paddingY: 6,
          backgroundColor: (theme) => theme.palette.background.tertiary,
          borderTop: 1,
          borderColor: "divider",
          zIndex: 1200,
        }}
      >
        {isDefined(placementDetails) && isDefined(placementCandidate) && (
          <Stack spacing={5}>
            {placementCandidate.status === PlacementCandidateStatus.ACTIVATED && (
              <>
                {applyButtonProps.warning && (
                  <Text variant="body2" color="text.secondary" sx={{ textAlign: "center" }}>
                    {applyButtonProps.warning}
                  </Text>
                )}
                {showBookInterviewButton ? (
                  <Stack spacing={5}>
                    <Button
                      fullWidth
                      variant="contained"
                      size="large"
                      disabled={
                        hasApplied ||
                        isCreatingPlacementApplication ||
                        isLoadingInterviewAvailability
                      }
                      onClick={() => {
                        bookInterviewModalState.openModal();
                      }}
                    >
                      Book an interview
                    </Button>
                    <Stack spacing={1}>
                      <Text variant="body1" textAlign="center">
                        You&apos;ll receive $20 for travel expenses once
                      </Text>
                      <Text variant="body1" textAlign="center">
                        your interview is completed
                      </Text>
                    </Stack>
                  </Stack>
                ) : (
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    size="large"
                    color="primary"
                    isLoading={isCreatingPlacementApplication || isLoadingInterviewAvailability}
                    disabled={hasApplied}
                    onClick={applyButtonProps.onClick}
                  >
                    {applyButtonProps.label}
                  </LoadingButton>
                )}
              </>
            )}
            {placementCandidate.status === PlacementCandidateStatus.ONBOARDING && (
              <>
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  onClick={() => {
                    createCandidateProfileModalState.openModal();
                  }}
                >
                  Create a Profile
                </Button>
                <Text variant="body2" color="text.secondary" sx={{ textAlign: "center" }}>
                  You must create a profile before you can apply for this job.
                </Text>
              </>
            )}
          </Stack>
        )}
      </Box>
      {placementApplicationSuccessModalState.modalIsOpen && (
        <PlacementApplicationSuccessBottomSheet
          modalState={placementApplicationSuccessModalState}
          placementDetails={placementDetails}
        />
      )}
      {createCandidateProfileModalState.modalIsOpen && (
        <PlacementDetailCreateCandidateProfileDialog
          modalState={createCandidateProfileModalState}
          placementCandidate={placementCandidate}
          worker={worker}
        />
      )}
      {bookInterviewModalState.modalIsOpen && (
        <PlacementDetailBookInterviewDialog
          modalState={bookInterviewModalState}
          interviewAvailability={interviewAvailability}
          onBookInterview={async (time) => {
            setSelectedInterviewTime(time);
            bookInterviewModalState.closeModal();
            await createPlacementApplication({
              placementCandidateId: placementCandidate.id,
              placementDetails,
              scheduleInterview: {
                start: zonedTimeToUtc(time.start, workerTimezone).toISOString(),
                end: zonedTimeToUtc(time.end, workerTimezone).toISOString(),
              },
            });
            await refetchPlacement();
            addInterviewToCalendarModalState.openModal();
          }}
        />
      )}
      {addInterviewToCalendarModalState.modalIsOpen && isDefined(selectedInterviewTime) && (
        <InterviewBookedDialog
          modalState={addInterviewToCalendarModalState}
          placementDetails={placementDetails}
          selectedInterviewTime={selectedInterviewTime}
        />
      )}
    </>
  );
}
